export default {
  apiKey: "AIzaSyDWENyGct5TvrWpP4XRSvWCifpW2XK7gxA",
  authDomain: "trello-vue-app-4e196.firebaseapp.com",
  projectId: "trello-vue-app-4e196",
  storageBucket: "trello-vue-app-4e196.appspot.com",
  messagingSenderId: "755973612994",
  appId: "1:755973612994:web:47a47df57ba1da7748225b"
};
/******************************************
 PARA OBTENER ESTA INFORMACION SE DEBE
 - CREAR CUENTA EN FIREBASE
 - IR A REALTIME DATABASE
    - CREAR UN PROYECTO
- IR A DESCRIPCION GENERAL DEL PROYECTO
    - sELECCIONAR AGREGAR APP WEB
    - INGRESAR EL NOMBRE
    - SELECCIONAR FIREBASE HOSTING
    -SEGUIR CON LOS PASOS
      - npm install -g firebase-tools
      - firebase login
      - firebase init
        - Realtime Database: Configure a security rules file for Realtime Database and (optionally) provision default instance
        - Use an existing project
        - seleccionar el proyecto creado
      - firebase deploy
    -SELECCIONAR LA APP CREADA
      -EN GENERAL BAJAR Y COPIAR CONTENIDO firebaseConfig  ACA
*******************************************/
